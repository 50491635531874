// @flow
import * as React from 'react'
import { graphql } from 'gatsby'
import HomeHeaderBanner from '../images/banners/home-header-banner.png'
import HomeRoleBanner from '../images/banners/role-banner.png'
import EngineerBanner from '../images/banners/engineer-banner.png'
import DesignerBanner from '../images/banners/designer-banner.png'
import ProductBanner from '../images/banners/product-banner.png'
import Layout from '../components/layout'
import '../css/main.min.css'
import Accordion from '../components/accordion'
import LevelItem from '../components/level-item'
import styled from 'styled-components'
import {
  LevelsTextWrapper,
  LevelsContainer,
  FrameworkHeader,
  LevelHeader,
  RoleTab,
  RoleTabItem,
} from './../components/styles'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import xss from 'xss'
type Props = {
  data: Object,
}

type State = {
  level: number,
  activeTab: number,
}

type RenderLevelProps = {
  data: Object,
  activeLevel: ?number,
}

type RenderDiagramProps = {
  diagram: string,
}

type RenderRoleContentProps = {
  activeTab: number,
}

const Header = styled.div`
  display: flex;
`

const HeaderText = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 50%;
  justify-content: center;
`

const HeaderTitle = styled.h4`
  color: #352f26;
  font-size: 40px;
  font-weight: 600;
  line-height: 48px;
`

const HeaderSubtitle = styled.p`
  color: #1d192b;
  font-size: 16px;
  line-height: 24px;
`

const HeaderBanner = styled.img`
  width: 50%;
`

const IntroSection = styled.section`
  display: flex;
  gap: 56px;
  width: 100%;
`

const IntroLists = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  flex-basis: 70%;
`

const RoleSection = styled.section`
  display: flex;
  flex-direction: column;
`

const RoleHeader = styled.div`
  display: flex;
  gap: 80px;
  height: 260px;
  margin-bottom: 80px;
`

const RoleHeaderText = styled(HeaderTitle)`
  width: 32%;
`

const RoleBanner = styled.img`
  width: 61%;
  margin-top: 0;
`

const RoleLevelHeaderText = styled(LevelHeader)`
  margin-top: 0;
`

const RoleLevelHeader = styled.div`
  display: flex;
  justify-content: space-between;
`

const RoleFrameworkHeader = styled(FrameworkHeader)`
  gap: 40px;
`

const RoleLink = styled.a`
  height: 40px;
  padding: 8px 16px;
  border-radius: 12px;
  border: 1px solid #7e603d;
  margin-top: 0;
  gap: 8px;
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 20px;
  color: #7e603d !important;
  &:visited {
    color: #7e603d !important;
  }
`

const RoleContent = styled.div`
  display: flex;
  margin-bottom: 80;
  gap: 80px;
  min-height: 250px;
`

const RoleContentText = styled.div`
  border-left: 1px solid #e3d1ba;
  padding: 0 24px 40px;
  color: #202020;
  font-size: 16px;
  line-height: 24px;
  & > ul {
    margin: 32px 0;
  }
`

export default class Index extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      level: 0,
      activeTab: 0,
    }
  }

  onLevelClicked = (val: number) => (event: SyntheticUIEvent<>) => {
    event.preventDefault()
    this.setState({
      level: val,
      activeTab: 0,
    })
  }
  onTabClicked = (val: number) => (event: SyntheticUIEvent<>) => {
    event.preventDefault()
    this.setState({
      activeTab: val,
    })
  }

  renderLevels = ({ data, activeLevel }: RenderLevelProps) => {
    const toRender = []
    data.map((level, index) => {
      toRender.push(
        <LevelItem
          onClickHandler={this.onLevelClicked}
          key={index}
          index={index}
          title={level.title}
          active={activeLevel != null && index === activeLevel}
        ></LevelItem>,
      )
    })
    return toRender
  }

  renderDiagram = ({ diagram }: RenderDiagramProps) => {
    let imgSrc = EngineerBanner
    if (diagram === 'engineer') imgSrc = EngineerBanner
    if (diagram === 'designer') imgSrc = DesignerBanner
    if (diagram === 'product') imgSrc = ProductBanner
    return <img src={imgSrc} />
  }

  render() {
    const { intro: intros, roleIntro } = this.props.data.pageData.frontmatter
    const { level, activeTab } = this.state

    const renderRoleContent = ({ activeTab }: RenderRoleContentProps) => {
      return (
        <RoleContentText
          dangerouslySetInnerHTML={{
            __html: xss(roleIntro[level].criteria[activeTab].content),
          }}
        />
      )
    }
    return (
      <Layout data={this.props.data}>
        <Header>
          <HeaderText>
            <HeaderTitle>Welcome to our Career Framework! </HeaderTitle>
            <HeaderSubtitle>
              This tool has been designed to help you navigate your career path
              within our organization and provide a clear understanding of the
              skills, knowledge, and experience required to advance in your
              career.
              <br />
              Please spend some minutes reading the below guidelines for the
              best use of this tool.
            </HeaderSubtitle>
          </HeaderText>
          <HeaderBanner src={HomeHeaderBanner} alt="" />
        </Header>
        <IntroSection>
          <IntroLists>
            {intros.map(intro => (
              <Accordion
                key={intro.subtitle}
                title={intro.title}
                layeredContent={intro.content}
              />
            ))}
          </IntroLists>
        </IntroSection>
        <RoleSection>
          <RoleHeader>
            <RoleHeaderText>What is in the Career Framework?</RoleHeaderText>
            <RoleBanner src={HomeRoleBanner} alt="" />
          </RoleHeader>
          <RoleFrameworkHeader>
            <LevelsContainer>
              <LevelsTextWrapper>
                {this.renderLevels({ data: roleIntro, activeLevel: level })}
              </LevelsTextWrapper>
            </LevelsContainer>
            <RoleLevelHeader>
              <RoleLevelHeaderText>
                {roleIntro[level].title}
              </RoleLevelHeaderText>
              <RoleLink href={roleIntro[level].path}>
                Read more
                <FontAwesomeIcon
                  icon={faArrowRight}
                  color="#646464"
                  width={24}
                  height={24}
                />
              </RoleLink>
            </RoleLevelHeader>
          </RoleFrameworkHeader>
          {this.renderDiagram({ diagram: roleIntro[level].diagram })}
          <RoleContent>
            <RoleTab>
              {roleIntro[level].criteria.map((criteria, index) => (
                <RoleTabItem
                  key={`${criteria.name}-${index}`}
                  onClick={this.onTabClicked(index)}
                  active={activeTab === index}
                >
                  {criteria.name}
                </RoleTabItem>
              ))}
            </RoleTab>
            {renderRoleContent({ activeTab })}
          </RoleContent>
        </RoleSection>
      </Layout>
    )
  }
}

export const pageQuery = graphql`
  query {
    allMarkdownRemark {
      edges {
        node {
          id
          excerpt(pruneLength: 250)
          frontmatter {
            path
            sidebarTitle
            sidebarGroup
          }
        }
      }
    }
    pageData: markdownRemark(frontmatter: { homepage: { eq: true } }) {
      html
      frontmatter {
        path
        title
        yaml
        homepage
        roleIntro {
          title
          path
          diagram
          criteria {
            name
            content
          }
        }
        intro {
          title
          subtitle
          content {
            title
            content
          }
        }
      }
    }
  }
`
